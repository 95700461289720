<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>积分管理</el-breadcrumb-item>
        <el-breadcrumb-item>签到奖励</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">签到天数：</div>
<!--                <el-input-number type="number" :step-strictly="true" :controls="false" :step="1"-->
<!--                                 v-model="search.signDay"-->
<!--                                 placeholder="请输入签到天数(1-7)"></el-input-number>-->
                <el-input type="number" v-model="search.signDay" placeholder="请输入签到天数(1-7)"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="rewardAllocationAdd" @click="goAdd">新增签到奖励</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="signDay" label="签到天数"></el-table-column>
            <el-table-column align="center" prop="rewardPoints" label="奖励积分"></el-table-column>
            <el-table-column align="center" prop="extraBonus" label="额外奖励"></el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="rewardAllocationUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="签到天数：" prop="signDay">
          <span v-if="type === 'details'">{{ upData.signDay }}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.signDay"
                           placeholder="请输入签到天数(1-7)"></el-input-number>
        </el-form-item>
        <el-form-item label="奖励积分：" prop="rewardPoints">
          <span v-if="type === 'details'">{{ upData.rewardPoints }}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.rewardPoints"
                           placeholder="请输入奖励积分"></el-input-number>
        </el-form-item>
        <el-form-item label="额外奖励：" prop="extraBonus">
          <span v-if="type === 'details'">{{ upData.extraBonus }}</span>
          <el-input-number v-else type="number" :step-strictly="true" :controls="false" :step="1"
                           v-model="upData.extraBonus"
                           placeholder="请输入额外奖励积分"></el-input-number>
        </el-form-item>
      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const validateExtraBonus = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入额外奖励'));
      } else {
        callback();
      }
    };
    const validateRewardPoints = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入奖励积分'));
      } else {
        callback();
      }
    };
    const validateSignDay = (rule, value, callback) => {
      if (value === 0 || value === undefined) {
        callback(new Error('请输入签到天数'));
      } else {
        callback();
      }
    };
    return {
      search: {
        signDay: null
      },
      type: "",
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        rewardPoints: 0,
        signDay: 0,
        extraBonus: 0
      },
      rules: {
        extraBonus: [{required: true, validator: validateExtraBonus, trigger: "blur"}],
        rewardPoints: [{required: true, validator: validateRewardPoints, trigger: "blur"}],
        signDay: [{required: true, validator: validateSignDay, trigger: "blur"}],
      },
      rewardAllocationAdd: false,
      rewardAllocationUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }

      _this.$api
          .getRewardAllocation(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "rewardAllocation:add") {
          _this.rewardAllocationAdd = true;
        }
        if (v == "rewardAllocation:upd") {
          _this.rewardAllocationUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (!_this.upData.id) {
            _this.$api.addRewardAllocation(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editRewardAllocation(_this.upData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        rewardPoints: 0,
        signDay: 0,
        extraBonus: 0
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        signDay: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        rewardPoints: 0,
        signDay: 0,
        extraBonus: 0
      }
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.el-input-number {
  width: 100%;

  /deep/ .el-input__inner {
    text-align: left;
  }
}

.goods-box {
  margin-bottom: 20px;

  .search-title {
    width: 100px;
  }
}
</style>
